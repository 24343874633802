

















































































































































































































































































































































































































































import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import DataContainer from "@vuetifyx/common/DataContainer";
import userManager from "@/services/userManager";
import StatisticsBase from "../../../components/StatisticsBase.vue";
import { displayItemsWithName, displayItemsWithSku } from "@/utils/marketItemDisplay";
import moment from "moment";

export default Vue.extend({
  components: { StatisticsBase },
  data() {
    return {
      moment,
      userManager,
      self: this,
      orderFields: [
        { value: "estimatedAfterCount", text: "Số lượng cuối kì dự kiến" },
        { value: "estimatedAfterValue", text: "Giá trị cuối kì dự kiến" },
      ],
      ready: false,
      warehouses: [],
      tableOptions: {
        attrs: {
          "fixed-header": true,
        },
      },
      tableContainer: new DataContainer(),
      data: null,
      sortedProductGroups: [],
      sortedProducts: [],
      startTime: null,
      endTime: null,
      extraFilters: {},
      extraFiltersDefaultValue: {
        expirationDuration: 2 * 30 * 24 * 60 * 60 * 1000,
      },
      productGroupsPickerOptions: {
        attrs: {
          label: "Nhóm sản phẩm",
          "item-value": "_id",
          "item-text": "sku",
          "item-disabled": "_",
          multiple: true,
        },
        content: {
          async itemsSuggestor(search, value, limit) {
            const filters = [
              {
                key: "_id",
                operator: "equal_to",
                value,
              },
            ];
            const { items } = await coreApiClient.call("productGroups", "findAll", {
              payload: JSON.stringify({
                limit,
                search,
                filters,
              }),
            });
            return displayItemsWithSku(items);
          },
        },
      },
      productsPickerOptions: {
        attrs: {
          label: "Sản phẩm",
          "item-value": "_id",
          "item-text": "sku",
          "item-disabled": "_",
          multiple: true,
        },
        content: {
          async itemsSuggestor(search, value, limit) {
            const filters = [
              {
                key: "_id",
                operator: "equal_to",
                value,
              },
            ];
            const { items } = await coreApiClient.call("products", "findAll", {
              payload: JSON.stringify({
                limit,
                search,
                filters,
              }),
            });
            return displayItemsWithSku(items);
          },
        },
      },
      warehousesPickerOptions: {
        attrs: {
          label: "Kho",
          "item-value": "_id",
          "item-text": "name",
          "item-disabled": "_",
          multiple: true,
        },
        content: {
          async itemsSuggestor(search, value, limit) {
            const filters = [
              {
                key: "_id",
                operator: "equal_to",
                value,
              },
            ];
            const { items } = await coreApiClient.call("warehouses", "findAll", {
              payload: JSON.stringify({
                limit,
                search,
                filters,
              }),
            });
            return displayItemsWithName(items);
          },
        },
      },
      marketsPickerOptions: {
        attrs: {
          label: "Thị trường",
          "item-value": "_id",
          "item-text": "name",
          "item-disabled": "_",
          multiple: true,
        },
        content: {
          async itemsSuggestor(search, value, limit) {
            const filters = [
              {
                key: "_id",
                operator: "equal_to",
                value,
              },
            ];
            const { items } = await coreApiClient.call("markets", "findAll", {
              payload: JSON.stringify({
                limit,
                search,
                filters,
              }),
            });
            return items;
          },
        },
      },
      purchaseAlertedOptions: {
        type: "boolean",
        attrs: {
          label: "Cảnh báo nhập hàng",
        },
      },
      expirationDurationPickerOptions: {
        type: "select",
        attrs: {
          label: "Hạn sử dụng gần nhất theo tháng",
          items: [1, 2, 3, 4, 5, 6].map((item) => ({
            text: `${item} tháng`,
            value: item * 30 * 24 * 60 * 60 * 1000,
          })),
        },
      },
      shownProductGroups: {},
    };
  },
  async created() {
    {
      const { items } = await coreApiClient.call("warehouses", "findAll", {
        payload: JSON.stringify({
          limit: -1,
          filters: [
            {
              key: "disabled",
              operator: "not_equal_to",
              value: true,
            },
          ],
        }),
      });
      this.warehouses = items;
      this.ready = true;
    }
  },
  methods: {
    min(arr) {
      return Math.min(...arr);
    },
    refreshMakePromisesFunc({ startDate, endDate, extraFilters }) {
      const self = this;
      const promises: any = {};
      const timeFilter = (key, stage, options: any = {}) => {
        const filters = [];
        switch (stage) {
          case "b202306":
            filters.push({
              key,
              operator: "less_than",
              value: moment("2023-06-01").startOf("day").valueOf(),
            });
            break;
          case "before":
            filters.push({
              key,
              operator: "less_than",
              value: startDate,
            });
            break;
          case "after":
            filters.push({
              key,
              operator: "less_than_or_equal_to",
              value: endDate,
            });
            break;
          case "between":
            filters.push(
              {
                key,
                operator: "greater_than_or_equal_to",
                value: startDate,
              },
              {
                key,
                operator: "less_than_or_equal_to",
                value: endDate,
              }
            );
            break;
        }

        const startOfCurrentMonth = moment(startDate).startOf("month").valueOf();
        if (options.currentMonth) {
          filters.push({
            key,
            operator: "greater_than_or_equal_to",
            value: startOfCurrentMonth,
          });
        }
        if (options.beforeCurrentMonth) {
          filters.push({
            key,
            operator: "less_than",
            value: startOfCurrentMonth,
          });
        }

        return filters;
      };

      const warehouseFilter = (key = "warehouseId") => {
        if (extraFilters.warehouseIds && extraFilters.warehouseIds.length) {
          return {
            key,
            operator: "in",
            value: extraFilters.warehouseIds,
          };
        } else {
          return {
            key,
            operator: "in",
            value: self.warehouses.map((item) => item._id),
          };
        }
      };

      const marketFilter = (key = "marketId") => {
        if (extraFilters.marketIds && extraFilters.marketIds.length) {
          return {
            key,
            operator: "in",
            value: extraFilters.marketIds,
          };
        }
      };

      const productGroupFilter = (key) => {
        if (extraFilters.productGroupIds && extraFilters.productGroupIds.length) {
          return {
            key,
            operator: "in",
            value: extraFilters.productGroupIds,
          };
        }
      };

      const productFilter = (key, operator = "in") => {
        if (extraFilters.productIds && extraFilters.productIds.length) {
          return {
            key,
            operator,
            value: extraFilters.productIds,
          };
        }
      };

      promises.products = coreApiClient.call("products", "findAll", {
        payload: JSON.stringify({
          limit: -1,
          filters: [
            extraFilters.productGroupIds &&
              extraFilters.productGroupIds.length && {
                key: "productGroupId",
                operator: "in",
                value: extraFilters.productGroupIds,
              },
            extraFilters.purchaseAlerted && {
              key: "purchaseAlerted",
              operator: "equal_to",
              value: true,
            },
            productFilter("_id", "in_id"),
          ],
        }),
      });

      promises.productGroups = coreApiClient.call("productGroups", "findAll", {
        payload: JSON.stringify({
          limit: -1,
          filters: [
            extraFilters.productGroupIds &&
              extraFilters.productGroupIds.length && {
                key: "_id",
                operator: "in_id",
                value: extraFilters.productGroupIds,
              },
          ],
        }),
      });

      promises.toBeExportedProducts = coreApiClient.call("orders", "getStatistic", {
        payload: JSON.stringify({
          findRequest: {
            filters: [
              marketFilter(),
              productGroupFilter("productItems.product.productGroupId"),
              {
                key: "status",
                operator: "in",
                value: ["kt", "cbpu", "cpu", "cxk"],
              },
              productFilter("productItems.product._id", "in_id"),
              {
                key: "telesaleConfirmed",
                operator: "in",
                value: [true, null],
              },
            ],
          },
          aggregations: {
            products: {
              type: "terms",
              magicFields: {
                id: {
                  funcName: "toString",
                  field: "productItems.product._id",
                },
              },
              subAggregations: {
                count: {
                  type: "sum",
                  field: "productItems.count",
                },
                value: userManager.checkRole(["kt"]) && {
                  type: "sum",
                  magicField: {
                    funcName: "multiply",
                    fields: [
                      "productItems.count",
                      {
                        funcName: "ifNull",
                        fields: ["productItems.estimatedBaseCostPerItem", 0],
                      },
                    ],
                  },
                },
              },
            },
          },
          expandFields: ["productItems"],
        }),
      });

      promises.toBeImportedProducts = coreApiClient.call("purchaseCommands", "getStatistic", {
        payload: JSON.stringify({
          id: `toBeImportedProducts`,
          expandFields: ["items"],
          findRequest: {
            filters: [
              warehouseFilter(),
              {
                key: "deliveryStatus",
                operator: "in",
                value: ["100", "200", "300", "400", "450"],
              },
              productFilter("items.productId"),
            ],
          },
          aggregations: {
            count: {
              type: "sum",
              magicField: {
                funcName: "max",
                fields: [
                  {
                    funcName: "subtract",
                    fields: [
                      "items.count",
                      {
                        funcName: "ifNull",
                        fields: ["items.doneCount", 0],
                      },
                    ],
                  },
                  {
                    value: 0,
                  },
                ],
              },
            },
            value: userManager.checkRole(["kt"]) && {
              type: "sum",
              magicField: {
                funcName: "multiply",
                fields: [
                  {
                    funcName: "max",
                    fields: [
                      {
                        funcName: "subtract",
                        fields: [
                          "items.count",
                          {
                            funcName: "ifNull",
                            fields: ["items.doneCount", 0],
                          },
                        ],
                      },
                      {
                        value: 0,
                      },
                    ],
                  },
                  {
                    funcName: "ifNull",
                    fields: ["items.costPerUnit", 0],
                  },
                ],
              },
            },
            products: {
              type: "terms",
              magicFields: {
                id: "items.productId",
              },
              subAggregations: {
                count: {
                  type: "sum",
                  magicField: {
                    funcName: "max",
                    fields: [
                      {
                        funcName: "subtract",
                        fields: [
                          "items.count",
                          {
                            funcName: "ifNull",
                            fields: ["items.doneCount", 0],
                          },
                        ],
                      },
                      {
                        value: 0,
                      },
                    ],
                  },
                },
                value: userManager.checkRole(["kt"]) && {
                  type: "sum",
                  magicField: {
                    funcName: "multiply",
                    fields: [
                      {
                        funcName: "max",
                        fields: [
                          {
                            funcName: "subtract",
                            fields: [
                              "items.count",
                              {
                                funcName: "ifNull",
                                fields: ["items.doneCount", 0],
                              },
                            ],
                          },
                          {
                            value: 0,
                          },
                        ],
                      },
                      {
                        funcName: "ifNull",
                        fields: ["items.costPerUnit", 0],
                      },
                    ],
                  },
                },
              },
            },
          },
        }),
      });

      promises.warehouseImports = coreApiClient.call("warehouseImports", "findAll", {
        payload: JSON.stringify({
          id: `warehouseImports`,
          limit: -1,
          filters: [
            warehouseFilter(),
            productFilter("productId"),
            {
              key: "importType",
              operator: "equal_to",
              value: "nm",
            },
          ],
          includedProperties: ["_id", "productId", "warehouseId", "count", "failedCount", "expirationTime", "deliveriedTime"],
        }),
      });

      promises.productMonthInfos = coreApiClient.call("productMonthInfos", "findAll", {
        payload: JSON.stringify({
          limit: -1,
          filters: [timeFilter("month", "between")],
        }),
      });

      const stageFactory = (stage) => {
        return {
          importedProducts: coreApiClient.call("warehouseImports", "getStatistic", {
            payload: JSON.stringify({
              id: `${stage}_importedProducts`,
              findRequest: {
                filters: [
                  warehouseFilter(),
                  ...timeFilter("deliveriedTime", stage),
                  {
                    key: "importType",
                    operator: "equal_to",
                    value: "nm",
                  },
                  {
                    key: "deliveryStatus",
                    operator: "equal_to",
                    value: "500",
                  },
                  productFilter("productId"),
                ],
              },
              aggregations: {
                count: {
                  type: "sum",
                  field: "count",
                },
                products: {
                  type: "terms",
                  magicFields: {
                    id: "productId",
                  },
                  subAggregations: {
                    count: {
                      type: "sum",
                      field: "count",
                    },
                    value: userManager.checkRole(["kt"]) && {
                      type: "sum",
                      magicField: {
                        funcName: "multiply",
                        fields: [
                          "count",
                          {
                            funcName: "ifNull",
                            fields: ["baseCost", 0],
                          },
                        ],
                      },
                    },
                  },
                },
              },
            }),
          }),
          returnedProducts: coreApiClient.call("warehouseImports", "getStatistic", {
            payload: JSON.stringify({
              id: `${stage}_returnedProducts`,
              findRequest: {
                filters: [
                  warehouseFilter(),
                  ...timeFilter("purchaseTime", stage),
                  {
                    key: "importType",
                    operator: "equal_to",
                    value: "return",
                  },
                  {
                    key: "notReady",
                    operator: "not_equal_to",
                    value: true,
                  },
                  productFilter("productId"),
                ],
              },
              aggregations: {
                count: {
                  type: "sum",
                  magicField: {
                    funcName: "subtract",
                    fields: [
                      "count",
                      {
                        funcName: "ifNull",
                        fields: ["failedCount", 0],
                      },
                    ],
                  },
                },
                failedCount: {
                  type: "sum",
                  magicField: {
                    funcName: "ifNull",
                    fields: ["failedCount", 0],
                  },
                },
                products: {
                  type: "terms",
                  magicFields: {
                    id: "productId",
                  },
                  subAggregations: {
                    count: {
                      type: "sum",
                      magicField: {
                        funcName: "subtract",
                        fields: [
                          "count",
                          {
                            funcName: "ifNull",
                            fields: ["failedCount", 0],
                          },
                        ],
                      },
                    },
                    failedCount: {
                      type: "sum",
                      magicField: {
                        funcName: "ifNull",
                        fields: ["failedCount", 0],
                      },
                    },
                    value: userManager.checkRole(["kt"]) && {
                      type: "sum",
                      magicField: {
                        funcName: "multiply",
                        fields: [
                          {
                            funcName: "subtract",
                            fields: [
                              "count",
                              {
                                funcName: "ifNull",
                                fields: ["failedCount", 0],
                              },
                            ],
                          },
                          {
                            funcName: "ifNull",
                            fields: ["baseCost", 0],
                          },
                        ],
                      },
                    },
                    failedValue: userManager.checkRole(["kt"]) && {
                      type: "sum",
                      magicField: {
                        funcName: "multiply",
                        fields: [
                          {
                            funcName: "ifNull",
                            fields: ["failedCount", 0],
                          },
                          {
                            funcName: "ifNull",
                            fields: ["baseCost", 0],
                          },
                        ],
                      },
                    },
                  },
                },
              },
            }),
          }),
          exportedProducts: coreApiClient.call("orders", "getStatistic", {
            payload: JSON.stringify({
              id: `${stage}_exportedProducts`,
              findRequest: {
                filters: [
                  marketFilter(),
                  warehouseFilter(),
                  productGroupFilter("productItems.product.productGroupId"),
                  ...timeFilter("dxkTime", stage, { currentMonth: true }),
                  {
                    key: "status",
                    operator: "in",
                    value: ["dxk", "ht"],
                  },
                  productFilter("productItems.product._id", "in_id"),
                ],
              },
              aggregations: {
                count: {
                  type: "sum",
                  magicField: {
                    funcName: "cond",
                    if: {
                      key: "market.tmdt",
                      operator: "$eq",
                      value: true,
                    },
                    then: {
                      funcName: "ifNull",
                      fields: ["productItems.dxkCount", "productItems.count"],
                    },
                    else: "productItems.count",
                  },
                },
                products: {
                  type: "terms",
                  magicFields: {
                    id: {
                      funcName: "toString",
                      field: "productItems.product._id",
                    },
                  },
                  subAggregations: {
                    count: {
                      type: "sum",
                      magicField: {
                        funcName: "cond",
                        if: {
                          key: "market.tmdt",
                          operator: "$eq",
                          value: true,
                        },
                        then: {
                          funcName: "ifNull",
                          fields: ["productItems.dxkCount", "productItems.count"],
                        },
                        else: "productItems.count",
                      },
                    },
                    value: userManager.checkRole(["kt"]) && {
                      type: "sum",
                      magicField: {
                        funcName: "multiply",
                        fields: [
                          {
                            funcName: "cond",
                            if: {
                              key: "market.tmdt",
                              operator: "$eq",
                              value: true,
                            },
                            then: {
                              funcName: "ifNull",
                              fields: ["productItems.dxkCount", "productItems.count"],
                            },
                            else: "productItems.count",
                          },
                          {
                            funcName: "ifNull",
                            fields: ["productItems.baseCost", 0],
                          },
                        ],
                      },
                    },
                  },
                },
              },
              expandFields: ["productItems"],
            }),
          }),
          exportedCalculatedProducts: coreApiClient.call("exportedCalculatedItems", "getStatistic", {
            payload: JSON.stringify({
              id: `${stage}_exportedCalculatedProducts`,
              findRequest: {
                filters: [marketFilter(), warehouseFilter(), ...timeFilter("month", stage, { beforeCurrentMonth: true }), productFilter("productId", "in")],
              },
              aggregations: {
                count: {
                  type: "sum",
                  field: "count",
                },
                products: {
                  type: "terms",
                  magicFields: {
                    id: {
                      funcName: "toString",
                      field: "productId",
                    },
                  },
                  subAggregations: {
                    count: {
                      type: "sum",
                      field: "count",
                    },
                    value: userManager.checkRole(["kt"]) && {
                      type: "sum",
                      magicField: {
                        funcName: "multiply",
                        fields: [
                          "count",
                          {
                            funcName: "ifNull",
                            fields: ["calculatedBaseCost", 0],
                          },
                        ],
                      },
                    },
                  },
                },
              },
            }),
          }),
          resetProducts: coreApiClient.call("warehouseResetCommands", "getStatistic", {
            payload: JSON.stringify({
              id: `${stage}_resetProducts`,
              findRequest: {
                filters: [warehouseFilter(), ...timeFilter("time", stage), productFilter("items.productId")],
              },
              aggregations: {
                count: {
                  type: "sum",
                  field: "items.deltaCount",
                },
                products: {
                  type: "terms",
                  magicFields: {
                    id: "items.productId",
                  },
                  subAggregations: {
                    count: {
                      type: "sum",
                      field: "items.deltaCount",
                    },
                    value: userManager.checkRole(["kt"]) && {
                      type: "sum",
                      magicField: {
                        funcName: "multiply",
                        fields: [
                          "items.deltaCount",
                          {
                            funcName: "ifNull",
                            fields: ["items.deltaBaseCost", 0],
                          },
                        ],
                      },
                    },
                  },
                },
              },
              expandFields: ["items"],
            }),
          }),
          transferImportedProducts: coreApiClient.call("warehouseTransferCommands", "getStatistic", {
            payload: JSON.stringify({
              id: `${stage}_transferImportedProducts`,
              findRequest: {
                filters: [warehouseFilter("targetWarehouseId"), ...timeFilter("time", stage), productFilter("items.targetProductId")],
              },
              aggregations: {
                count: {
                  type: "sum",
                  field: "items.count",
                },
                products: {
                  type: "terms",
                  magicFields: {
                    id: "items.targetProductId",
                  },
                  subAggregations: {
                    count: {
                      type: "sum",
                      field: "items.count",
                    },
                    value: userManager.checkRole(["kt"]) && {
                      type: "sum",
                      magicField: {
                        funcName: "multiply",
                        fields: [
                          "items.count",
                          {
                            funcName: "ifNull",
                            fields: ["items.baseCost", 0],
                          },
                        ],
                      },
                    },
                  },
                },
              },
              expandFields: ["items"],
            }),
          }),
          transferExportedProducts: coreApiClient.call("warehouseTransferCommands", "getStatistic", {
            payload: JSON.stringify({
              id: `${stage}_transferExportedProducts`,
              findRequest: {
                filters: [warehouseFilter("srcWarehouseId"), ...timeFilter("time", stage), productFilter("items.srcProductId")],
              },
              aggregations: {
                count: {
                  type: "sum",
                  field: "items.count",
                },
                products: {
                  type: "terms",
                  magicFields: {
                    id: "items.srcProductId",
                  },
                  subAggregations: {
                    count: {
                      type: "sum",
                      field: "items.count",
                    },
                    value: userManager.checkRole(["kt"]) && {
                      type: "sum",
                      magicField: {
                        funcName: "multiply",
                        fields: [
                          "items.count",
                          {
                            funcName: "ifNull",
                            fields: ["items.baseCost", 0],
                          },
                        ],
                      },
                    },
                  },
                },
              },
              expandFields: ["items"],
            }),
          }),
        };
      };

      ["b202306", "before", "after", "between"].forEach((stage) => {
        const stageKey = stage[0].toUpperCase() + stage.slice(1);
        const stageItems = stageFactory(stage);
        for (const k in stageItems) {
          promises[`${k}${stageKey}`] = stageItems[k];
        }
      });

      return promises;
    },
    exportMakeUrlFunc(queries) {
      if (this.user) {
        queries.userId = this.user._id;
      }
      return {
        path: "exportInWarehouseProducts",
        queries,
      };
    },
    refreshed(data, { extraFilters }, { startDay, endDay, orderBy, orderType }) {
      this.data = data;
      {
        this.startDate = startDay().valueOf();
        this.endDate = endDay().valueOf();
        this.extraFilters = extraFilters;
      }
      {
        let items = data.products.items.filter((item) => item.country?.code !== "test");
        const productGroupIdAfterCountMap: any = {};
        items.forEach((item) => {
          productGroupIdAfterCountMap[item.productGroupId] = productGroupIdAfterCountMap[item.productGroupId] || 0;
          productGroupIdAfterCountMap[item.productGroupId] += Math.abs(this.afterCount(item._id));
        });
        items = items.sort(({ _id: aProductId }, { _id: bProductId }) => {
          const a = data.products.items.find((item) => item._id === aProductId);
          const b = data.products.items.find((item) => item._id === bProductId);
          if (!a) {
            return 1;
          } else if (!b) {
            return -1;
          }
          return Math.abs(productGroupIdAfterCountMap[b.productGroupId] || 0) - Math.abs(productGroupIdAfterCountMap[a.productGroupId] || 0);
        });
        this.sortedProducts = displayItemsWithSku(items);
      }
      {
        let items = this.data.productGroups.items.filter((item) => item.country?.code !== "test");
        items.forEach((item) => {
          item.products = this.data.products.items.filter((p) => p.productGroupId === item._id);
          item.beforeCount = item.products.reduce((total, { _id: productId }) => {
            return total + this.beforeCount(productId);
          }, 0);
          item.betweenCount = item.products.reduce((total, { _id: productId }) => {
            return total + this.betweenCount(productId);
          }, 0);
          item.betweenHasCount = item.products.some(({ _id: productId }) => {
            return this.betweenHasCount(productId);
          }, 0);
          item.afterCount = item.products.reduce((total, { _id: productId }) => {
            return total + this.afterCount(productId);
          }, 0);
          item.afterValue = item.products.reduce((total, { _id: productId }) => {
            return total + this.afterValue(productId);
          }, 0);
          item.toBeImportedCount = item.products.reduce((total, { _id: productId }) => {
            return total + this.cellValue("toBeImportedProducts", "products", productId, "count", 0);
          }, 0);
          item.toBeImportedValue = item.products.reduce((total, { _id: productId }) => {
            return total + this.cellValue("toBeImportedProducts", "products", productId, "value", 0);
          }, 0);
          item.toBeExportedCount = item.products.reduce((total, { _id: productId }) => {
            return total + this.cellValue("toBeExportedProducts", "products", productId, "count", 0);
          }, 0);
          item.toBeExportedValue = item.products.reduce((total, { _id: productId }) => {
            return total + this.cellValue("toBeExportedProducts", "products", productId, "count", 0) * this.baseCostAfter(productId);
          }, 0);
        });
        items = items
          .filter((item) => item.beforeCount || item.betweenHasCount || item.afterCount || item.toBeImportedCount)
          .sort((a, b) => {
            let aValue = a.afterCount;
            let bValue = b.afterCount;

            switch (orderBy) {
              case "estimatedAfterCount":
                aValue = (a.afterCount || 0) + (a.toBeImportedCount || 0) - (a.toBeExportedCount || 0);
                bValue = (b.afterCount || 0) + (b.toBeImportedCount || 0) - (b.toBeExportedCount || 0);
                break;
              case "estimatedAfterValue":
                aValue = (a.afterValue || 0) + (a.toBeImportedValue || 0) - (a.toBeExportedValue || 0);
                bValue = (b.afterValue || 0) + (b.toBeImportedValue || 0) - (b.toBeExportedValue || 0);
                break;
              default:
                aValue = a.afterCount;
                bValue = b.afterCount;
                break;
            }

            let result = (bValue || 0) - (aValue || 0);
            if (orderType === "asc") {
              result *= -1;
            }
            return result;
          });
        this.sortedProductGroups = displayItemsWithSku(items);
      }
      this.contentResized();
    },
    contentResized() {
      this.$nextTick(() => {
        const self = this;
        const timer = setInterval(() => {
          const table = self.tableContainer.value;
          if (!table) {
            return;
          }
          table.recalculateHeight();
          clearInterval(timer);
        }, 200);
      });
    },
    cellValue(source, mainKey, key, subKey, defaultValue = "") {
      if (mainKey === "products" && key === true) {
        return this.sortedProductGroups.reduce((total, { products }) => {
          return (
            total +
            products.reduce((total2, { _id: productId }) => {
              return total2 + this.cellValue(source, mainKey, productId, subKey, defaultValue);
            }, 0)
          );
        }, 0);
      }
      if (source === "toBeExportedProducts" && subKey === "value") {
        return this.cellValue("toBeExportedProducts", "products", key, "count", defaultValue) * this.baseCostAfter(key);
      }

      const sourceValue = this.data[source];
      if (!sourceValue) {
        return defaultValue;
      }
      if (!mainKey) {
        return sourceValue;
      }
      const mainValue = sourceValue[mainKey];
      if (!mainValue) {
        return defaultValue;
      }
      if (!key) {
        return mainValue;
      }
      const value = mainValue[key];
      if (!value) {
        return defaultValue;
      }
      return (value && value.info && value.info[subKey]) || defaultValue;
    },
    b202306Count(productId) {
      return (
        this.cellValue("importedProductsB202306", "products", productId, "count", 0) +
        this.cellValue("returnedProductsB202306", "products", productId, "count", 0) -
        this.cellValue("exportedProductsB202306", "products", productId, "count", 0) -
        this.cellValue("exportedCalculatedProductsB202306", "products", productId, "count", 0) +
        this.cellValue("resetProductsB202306", "products", productId, "count", 0) +
        this.cellValue("transferImportedProductsB202306", "products", productId, "count", 0) -
        this.cellValue("transferExportedProductsB202306", "products", productId, "count", 0)
      );
    },
    beforeCount(productId) {
      return (
        this.cellValue("importedProductsBefore", "products", productId, "count", 0) +
        this.cellValue("returnedProductsBefore", "products", productId, "count", 0) -
        this.cellValue("exportedProductsBefore", "products", productId, "count", 0) -
        this.cellValue("exportedCalculatedProductsBefore", "products", productId, "count", 0) +
        this.cellValue("resetProductsBefore", "products", productId, "count", 0) +
        this.cellValue("transferImportedProductsBefore", "products", productId, "count", 0) -
        this.cellValue("transferExportedProductsBefore", "products", productId, "count", 0)
      );
    },
    betweenCount(productId) {
      return (
        this.cellValue("importedProductsBetween", "products", productId, "count", 0) +
        this.cellValue("returnedProductsBetween", "products", productId, "count", 0) -
        this.cellValue("exportedProductsBetween", "products", productId, "count", 0) -
        this.cellValue("exportedCalculatedProductsBetween", "products", productId, "count", 0) +
        this.cellValue("resetProductsBetween", "products", productId, "count", 0) +
        this.cellValue("transferImportedProductsBetween", "products", productId, "count", 0) -
        this.cellValue("transferExportedProductsBetween", "products", productId, "count", 0)
      );
    },
    betweenHasCount(productId) {
      return (
        this.cellValue("importedProductsBetween", "products", productId, "count", 0) ||
        this.cellValue("returnedProductsBetween", "products", productId, "count", 0) ||
        this.cellValue("exportedProductsBetween", "products", productId, "count", 0) ||
        this.cellValue("exportedCalculatedProductsBetween", "products", productId, "count", 0) ||
        this.cellValue("resetProductsBetween", "products", productId, "count", 0) ||
        this.cellValue("transferImportedProductsBetween", "products", productId, "count", 0) ||
        this.cellValue("transferExportedProductsBetween", "products", productId, "count", 0)
      );
    },
    afterCount(productId) {
      return (
        this.cellValue("importedProductsAfter", "products", productId, "count", 0) +
        this.cellValue("returnedProductsAfter", "products", productId, "count", 0) -
        this.cellValue("exportedProductsAfter", "products", productId, "count", 0) -
        this.cellValue("exportedCalculatedProductsAfter", "products", productId, "count", 0) +
        this.cellValue("resetProductsAfter", "products", productId, "count", 0) +
        this.cellValue("transferImportedProductsAfter", "products", productId, "count", 0) -
        this.cellValue("transferExportedProductsAfter", "products", productId, "count", 0)
      );
    },
    baseCostBefore(productId) {
      const lastMonth = moment(this.startDate).add(-1, "month").startOf("month").valueOf();
      const item = this.data.productMonthInfos.items.find((item) => item.productId === productId && item.month === lastMonth);
      return item?.baseCost;
    },
    baseCostAfter(productId) {
      const item = this.data.productMonthInfos.items.find((item) => item.productId === productId && item.month === moment(this.endDate).startOf("month").valueOf());
      return item?.baseCost;
    },
    beforeValue(productId) {
      if (productId === true) {
        return this.sortedProductGroups.reduce((total, { products }) => {
          return (
            total +
            products.reduce((total2, product) => {
              return total2 + this.beforeValue(product._id);
            }, 0)
          );
        }, 0);
      }
      const baseCost = this.baseCostBefore(productId);
      if (baseCost) {
        return this.beforeCount(productId) * baseCost;
      }
      let initValue = 0;
      const product = this.data.products.items.find((item) => item._id === productId);
      if (product?.baseCostCalculateInitValue && product?.baseCostCalculateInitCount) {
        initValue = (this.b202306Count(product._id) * product?.baseCostCalculateInitValue) / product?.baseCostCalculateInitCount;
      }
      return (
        initValue +
        this.cellValue("importedProductsBefore", "products", productId, "value", 0) +
        this.cellValue("returnedProductsBefore", "products", productId, "value", 0) -
        this.cellValue("exportedProductsBefore", "products", productId, "value", 0) -
        this.cellValue("exportedCalculatedProductsBefore", "products", productId, "value", 0) +
        this.cellValue("resetProductsBefore", "products", productId, "value", 0) +
        this.cellValue("transferImportedProductsBefore", "products", productId, "value", 0) -
        this.cellValue("transferExportedProductsBefore", "products", productId, "value", 0)
      );
    },
    afterValue(productId) {
      if (productId === true) {
        return this.sortedProductGroups.reduce((total, { products }) => {
          return (
            total +
            products.reduce((total2, product) => {
              return total2 + this.afterValue(product._id);
            }, 0)
          );
        }, 0);
      }
      const baseCost = this.baseCostAfter(productId);
      if (baseCost) {
        return this.afterCount(productId) * baseCost;
      }
      let initValue = 0;
      const product = this.data.products.items.find((item) => item._id === productId);
      if (product?.baseCostCalculateInitValue && product?.baseCostCalculateInitCount) {
        initValue = (this.b202306Count(product._id) * product?.baseCostCalculateInitValue) / product?.baseCostCalculateInitCount;
      }

      return (
        initValue +
        this.cellValue("importedProductsAfter", "products", productId, "value", 0) +
        this.cellValue("returnedProductsAfter", "products", productId, "value", 0) -
        this.cellValue("exportedProductsAfter", "products", productId, "value", 0) -
        this.cellValue("exportedCalculatedProductsAfter", "products", productId, "value", 0) +
        this.cellValue("resetProductsAfter", "products", productId, "value", 0) +
        this.cellValue("transferImportedProductsAfter", "products", productId, "value", 0) -
        this.cellValue("transferExportedProductsAfter", "products", productId, "value", 0)
      );
    },
    productPurchaseAlerted(productId, item) {
      return item.purchaseAvgPerDay > 0 && this.afterCount(productId) / item.purchaseAvgPerDay <= item.purchaseAvgPerDayThreshold;
    },
    toggleProductGroup(productGroupId) {
      this.$set(this.shownProductGroups, productGroupId, !this.shownProductGroups[productGroupId]);
    },
    warehouseImportsForAfterCount(productId: string, afterCount?: number) {
      if (afterCount === undefined) {
        afterCount = this.afterCount(productId);
      }
      const items = [];
      const filteredItems = this.data.warehouseImports.items.filter((item) => item.productId === productId).sort((a, b) => (b.expirationTime || b.deliveriedTime || 0) - (a.expirationTime || a.deliveriedTime || 0));
      let currentCount = 0;
      for (const item of filteredItems) {
        if (currentCount >= afterCount) {
          break;
        }
        items.push(item);
        currentCount += item.count;
      }
      return items;
    },
    soonExpirationCount(productId: string, afterCount?: number) {
      if (afterCount === undefined) {
        afterCount = this.afterCount(productId);
      }
      const items = this.warehouseImportsForAfterCount(productId, afterCount);
      let soonExpiredCount = 0;
      let totalCount = 0;
      for (const item of items) {
        const remainingCount = afterCount - totalCount;
        if (item.expirationTime && item.expirationTime < this.endDate + this.extraFilters.expirationDuration) {
          soonExpiredCount += Math.min(item.count, remainingCount);
        }
        totalCount += item.count;
        if (totalCount >= afterCount) {
          break;
        }
      }
      return soonExpiredCount;
    },
    soonExpirationTime(productId: string, afterCount?: number) {
      if (afterCount === undefined) {
        afterCount = this.afterCount(productId);
      }
      const items = this.warehouseImportsForAfterCount(productId, afterCount);
      let totalCount = 0;
      let value;
      for (const item of items) {
        if (item.expirationTime && item.expirationTime < this.endDate + this.extraFilters.expirationDuration) {
          value = item.expirationTime;
        }
        totalCount += item.count;
        if (totalCount >= afterCount) {
          break;
        }
      }
      return value;
    },
  },
});
